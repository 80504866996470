.unit {
  font-size: var(--font-size);
}

.hundred {
  position: relative;
  padding: 0 0.4rem;
  width: 4rem;
}

.hundred::before {
  content: ",";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
